module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Startup Umbrella","short_name":"StartupUmbrella","start_url":"/","background_color":"#0593FF","theme_color":"#0593FF","display":"minimal-ui","icon":"src/images/umbrella-icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"116389edf72bd53e77c7de0a8af1f9a6"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-H5WM42L4R0"],"gtagConfig":{"optimize_id":"14810160","anonymize_ip":false,"cookie_expires":0},"pluginConfig":{"head":false,"respectDNT":true,"exclude":["/preview/**","/do-not-track/me/too/"]}},
    },{
      plugin: require('../node_modules/gatsby-plugin-facebook-pixel/gatsby-browser.js'),
      options: {"plugins":[],"pixelId":"902012274060759"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
